<template>
	<div id="main">
		<div id="nav">
			<van-row>
				<van-col span="8">
					<van-image round width="6rem" height="6rem" src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJephH9wCRG10ic708D9kANssuYLS5ZFiblvJSCdRTj6XwtRREmUdJHLujcjpiaUzSXO2WF6UVmbHqKQ/132" />
				</van-col>
				<van-col span="2">
				</van-col>
				<van-col span="14">
					<van-row>
						唯
					</van-row>
					<van-row>

					</van-row>
				</van-col>
			</van-row>

		</div>
		<div style="">
			<van-grid clickable :column-num="3">
				<van-grid-item icon="home-o" text="节点状态" to="/" />
				<van-grid-item icon="home-o" text="订阅管理" to="/" />
				<van-grid-item icon="search" text="软件下载" url="/vant/mobile.html" />
			</van-grid>
			<van-grid clickable :column-num="3">
				<van-grid-item icon="home-o" text="使用教程" to="/" />
			</van-grid>
			<van-swipe :autoplay="3000">
				<van-swipe-item v-for="(image, index) in images" :key="index">
					<img v-lazy="image" />
				</van-swipe-item>
			</van-swipe>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			function() {}
		}
	}
</script>

<style>
	.custom-title {
		margin-right: 4px;
		vertical-align: middle;
	}

	#home {
		text-align: center;
	}

	.search-icon {
		font-size: 16px;
		line-height: inherit;
	}
</style>
